import { useState, useEffect } from 'react';

export const useIntersection = (element) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) { // only fire the event once
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      { rootMargin: '0px' }
    );

    element?.current && observer.observe(element.current);

    return () => element?.current && observer.unobserve(element.current);
  }, []);

  return isVisible;
};
