import React from "react"
import '../custom.css';
import {CookieBanner, FinanceBanner, VehicleCompareBanner} from 'dealer-website-components';

const Layout = props => {
    const {context} = props;

    return (
        <div className="font-body" itemscope="" itemtype="http://schema.org/AutoDealer">
            {props.children}

            {context.financing &&
            <FinanceBanner location="bottom"
                           context={context}/>}

            {context.consent &&
            <CookieBanner
                location="bottom"
                context={context}
            />}

            {/*<GlobalModal context={context}/>*/}

            <VehicleCompareBanner context={context}/>
        </div>
    )
};

Layout.propTypes = {};

export default Layout
