import React, { useEffect } from 'react';
import { hasWindow, isBrowserSupported } from './utils';
import { includes, orderBy } from 'lodash';
import Layout from './components/Layout';
import BrowserNotSupported from './components/not-supported/BrowserNotSupported';
import Block from './components/Block';
import { DataContext, CaptchaProvider, useAnalytics, useSession } from 'dealer-website-components';
import { graphql } from 'gatsby';
import settings, { default as customerSettings } from '../config';
import Helmet from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import CustomBlock from './components/CustomBlock';
import analyticstracker from 'analyticstracker';
import analyticsTransQA from 'analytics-transQA';
import analyticsTransGTM from 'analytics-transGTM';
import { getAnalyticsTracker } from './analytics/analytics';

const Page = (props) => {
  const { page, meta, favoriteIconUrl, componentContext } = props.pageContext;
  const { t } = useTranslation();
  const context = {
    ...componentContext,
    t: t
  };

  const { session } = useSession(context);
  const pageVariables = { reference: props.reference };

  // OLD V1 implementation of analytics
  const { analyticsInitialize, analyticsEventSubmit, pageImpressionEventInitialize } = useAnalytics(context);

  // New implementation of analytics
  const { initializeTracker, analyticsTracker } = useAnalytics(context);

  useEffect(() => {
    if (page.type !== componentContext.defaultPageTypes.vehicles && hasWindow()) {
      // by setting shouldUpdateScroll to false in gatbsy-browser.js (for stock page when changing filters) this is needed for all other pages..
      window.scrollTo(0, 0);
    }

    if (hasWindow() && settings.VERSION >= 1.1) {
      const tracker = getAnalyticsTracker();
      initializeTracker(tracker);
      window.analyticsTracker = tracker;
    } else {
      // vehicleDetail needs more data, all other pages send data imediatly
      if (page.type !== componentContext.defaultPageTypes.vehicleDetail) {
        analyticsEventSubmit(pageImpressionEventInitialize('', undefined, context));
      }
    }
  }, []);

  useEffect(() => {
    if (hasWindow() && analyticsTracker !== undefined) {
      // vehicleDetail needs more data, all other pages send data imediatly
      if (page.type !== componentContext.defaultPageTypes.vehicleDetail) {
        const pageImpressionEvent = analyticsTracker.pageImpressionEventInitialize(page, undefined, context, session);
        analyticsTracker.analyticsEventSubmit(pageImpressionEvent);
      }
    }
  }, [analyticsTracker]);

  const browserSupported = isBrowserSupported();
  if (!browserSupported) {
    return (
      <div className='font-body bg-gray-100'>
        <BrowserNotSupported />
      </div>
    );
  }

  const resolveAnalyticsData = (type, data) => {
    if (settings.VERSION >= 1.1) {
      const pageImpressionEvent = analyticsTracker.pageImpressionEventInitialize(page, data, context, session);
      analyticsTracker.analyticsEventSubmit(pageImpressionEvent);
    } else {
      analyticsEventSubmit(pageImpressionEventInitialize(type, data, context));
    }
  };

  const orderedBlocks = orderBy(page.blocks, 'order');

  return (
    <Layout context={componentContext}>
      <CaptchaProvider captchaKey={settings.CAPTCHA_KEY}>
        <DataContext.Consumer>
          {(value) => (
            <React.Fragment>
              <Helmet>
                <title>{meta.title}</title>
                <meta name='description' content={meta.description} />

                {!page.indexable && <meta name='robots' content='noindex' />}

                <link rel='icon' href={favoriteIconUrl} />

                {settings.GOOGLE_ANALYTICS_ID && settings.GOOGLE_ANALYTICS_ID != '' && (
                  <script>
                    {`window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${settings.GOOGLE_ANALYTICS_ID}');`}
                  </script>
                )}

                {settings.GOOGLE_TAG_MANAGER_ID && settings.GOOGLE_TAG_MANAGER_ID != '' && (
                  <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${settings.GOOGLE_TAG_MANAGER_ID}');`}
                  </script>
                )}
              </Helmet>

              {orderedBlocks.map((block) => {
                if (block.contentType !== 'CustomGeneratedBlockInBuilder') {
                  return <Block block={block} key={block.id} context={componentContext} store={value} resolveAnalyticsData={resolveAnalyticsData} pageVariables={pageVariables} />;
                } else {
                  return <CustomBlock block={block} key={block.id} context={componentContext} />;
                }
              })}
            </React.Fragment>
          )}
        </DataContext.Consumer>
      </CaptchaProvider>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
