
export const pageNameToType = {
  myway: {
    'myway/account': 'account',
    'myway/account/car-agent': 'account',
    'myway/account/favorites': 'account',
    'myway/account/login': 'form',
    'myway/account/manage': 'account',
    'myway/account/overview': 'account',
    'myway/account/password-change': 'form',
    'myway/account/password-recovery': 'form',
    'myway/account/password-reset': 'form',
    'myway/account/register': 'form',
    'myway/car-details': 'product',
    'myway/comparison': 'form',
    'myway/contact': 'support',
    'myway/contact/contact-dealer': 'form',
    'myway/contact/customer-service': 'form',
    'myway/contact/find-dealer': 'form',
    'myway/error': 'error',
    'myway/faq': 'support',
    'myway/home': 'homepage',
    'myway/legal': 'information',
    'myway/mywaydirect': 'information',
    'myway/myway-direct': 'information',
    'myway/privacy': 'information',
    'myway/reservation': 'form',
    'myway/search-agent': 'search',
    'myway/search-results': 'search',
    'myway/services-autocredit': 'information',
    'myway/services-classic-financing': 'information',
    'myway/services-financial-leasing': 'information',
    'myway/services-guarantee': 'information',
    'myway/services-long-term-renting': 'information',
    'myway/sitemap': 'information'
  },
  default: {
    'dealersite/used-cars': 'search',
    'dealersite/privacy': 'information',
    'dealersite/stock': 'search',
    'dealersite/used-cars/car-details': 'product',
    'dealersite/error': 'errorType',
    'dealersite/favorites': 'product',
    'dealersite/comparison': 'product',
    'dealersite/used-cars/search': 'search'
  }
};

export const buttonCategoryMap = {
  'internal-link': 'inbound-link',
  'external-link': 'outbound-link'
};

export const vehicleToCommerce = (vehicle) => {
  const { body, make, model, version, hp, gearboxKey, exteriorColor, interiorColor, equipments, firstRegistrationYear, mileage, price, identification, fuelKey } = vehicle;
  let optionsList = equipments.map((equipment) => equipment.key);

  return {
    carBodyType: body || '',
    carBrand: make || '',
    carModel: model || '',
    carEquipmentLine: version || '',
    carEngineType: fuelKey || '',
    carEnginePower: hp.toString() || '',
    carGearboxType: gearboxKey || '',
    carExteriorColor: exteriorColor || '',
    carInteriorColor: interiorColor || '',
    carConfigCode: '',
    carOptionsList: optionsList || '',
    referenceID: identification || '',
    carMileage: mileage.toString() || '',
    carYear: firstRegistrationYear.toString() || '',
    carTotalPrice: price.toString() || '',
    carPrice: ''
  };
};

export const vehicleToCommerceClick = (vehicle) => {
  const { body, make, model, version, hp, gearboxKey, exteriorColor, interiorColor, equipmentKeys, firstRegistrationYear, mileage, price, identification, fuelKey } = vehicle;

  return {
    carBodyType: body || '',
    carBrand: make || '',
    carModel: model || '',
    carEquipmentLine: version || '',
    carEngineType: fuelKey || '',
    carEnginePower: hp.toString() || '',
    carGearboxType: gearboxKey || '',
    carExteriorColor: exteriorColor || '',
    carInteriorColor: interiorColor || '',
    carConfigCode: '',
    carOptionsList: equipmentKeys || '',
    referenceID: identification || '',
    carMileage: mileage.toString() || '',
    carYear: firstRegistrationYear.toString() || '',
    carTotalPrice: price.toString() || '',
    carPrice: ''
  };
};

export const companyCodeToParams = {
  default: {
    platform: 'dealersite',
    brand: 'multibrand',
    pageviewMode: 'dealer'
  },
  myway: {
    platform: 'main',
    brand: 'myway',
    pageviewMode: 'national'
  }
};
